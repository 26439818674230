import React from 'react';
import FullWidth from 'containers/FullWidthContainer';
import Section from 'containers/SectionContainer';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    flex: '1 0 auto',
    marginBottom: '5rem',
  },
  footer: {
    flexShrink: 0,
  },
}));

interface PageContainerProps {
  children?: React.ReactNode | undefined;
}

const PageContainer: React.FC<PageContainerProps> = ({
  children,
}: PageContainerProps): JSX.Element => {
  const styles = useStyles({});
  const theme = useTheme();

  return (
    <>
      <div className={styles.page}>
        <FullWidth backgroundColor="transparent">
          <Section id="header">
            <Header />
          </Section>
        </FullWidth>
        {children || null}
      </div>
      <footer className={styles.footer}>
        <FullWidth backgroundColor={theme.palette.primary.dark}>
          <Section id="footer">
            <Footer />
          </Section>
        </FullWidth>
      </footer>
    </>
  );
};

export default PageContainer;
