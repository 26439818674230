import React from 'react';
import { Grid, Box, Typography, Link } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import classNames from 'classnames';
import LogoImage from 'assets/images/distriqt-logo.png';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    color: theme.palette.common.white,
  },
  footerBottom: {
    color: theme.palette.common.white,
    textAlign: 'right',
  },
  heading: {
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(3),
  },
  link: {
    display: 'block',
    color: theme.palette.common.white,
    '& svg': {
      marginRight: theme.spacing(1),
    },
    paddingBottom: theme.spacing(1.5),
  },
  linkSpaced: {
    paddingBottom: theme.spacing(4),
  },
}));

const Footer: React.FC = (): JSX.Element => {
  const styles = useStyles({});
  const year = new Date().getFullYear();

  return (
    <Box py={8}>
      <Grid container className={styles.footer}>
        <Grid item xs={12} md={3}>
          <Typography component="h4" variant="h4" className={styles.heading}>
            Contact
          </Typography>
          <Link href="/contact" className={classNames(styles.link, styles.linkSpaced)}>
            Contact us
          </Link>
          <Typography variant="body1">General enquiries</Typography>
          <Link
            href="mailto:info@distriqt.com"
            className={classNames(styles.link, styles.linkSpaced)}
          >
            info@distriqt.com
          </Link>
          <Typography>Michael Archbold</Typography>
          <Link
            href="mailto:ma@distriqt.com"
            className={classNames(styles.link, styles.linkSpaced)}
          >
            ma@distriqt.com
          </Link>
          <Typography>Shane Korin</Typography>
          <Link
            href="mailto:info@distriqt.com"
            className={classNames(styles.link, styles.linkSpaced)}
          >
            sk@distriqt.com
          </Link>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography component="h4" variant="h4" className={styles.heading}>
            Connect
          </Typography>
          <Link href="https://twitter.com/distriqt" className={styles.link}>
            <TwitterIcon />
            Twitter
          </Link>
          <Link href="https://facebook.com/distriqt" className={styles.link}>
            <FacebookIcon />
            Facebook
          </Link>
          <Link href="https://www.linkedin.com/company/325962" className={styles.link}>
            <LinkedInIcon />
            LinkedIn
          </Link>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography component="h4" variant="h4" className={styles.heading}>
            Explore
          </Typography>
          <div>
            <Link href="/about" className={styles.link}>
              About us
            </Link>
          </div>
          <div>
            <Link
              href="https://airnativeextensions.com"
              className={styles.link}
              target="_blank"
            >
              AIR Native Extensions
            </Link>
          </div>
          <div>
            <Link
              href="https://docs.airnativeextensions.com"
              className={styles.link}
              target="_blank"
            >
              Native extensions documentation
            </Link>
            <Link
              href="https://medium.com/airnativeextensions"
              className={styles.link}
              target="_blank"
            >
              Native extensions blog
            </Link>
          </div>
        </Grid>
      </Grid>
      <Grid container className={styles.footerBottom}>
        <Grid item xs={12}>
          <div>
            <img src={LogoImage} alt="distriqt logo" />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" component="p">
            Copyright © {year} DISTRIQT PTY LTD
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" component="p">
            ABN 87 744 050 243
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
