import React from 'react';
import './_includes';
import PageContainer from '../containers/PageContainer';
import FullWidth from 'containers/FullWidthContainer';
import Section from 'containers/SectionContainer';
import HeroSection from 'components/HeroSection';
import ContentSection from 'components/ContentSection';
import { Helmet } from 'react-helmet';
import bgImage from 'assets/images/promo-background-darker.jpg';
import { heroContent, introContent } from 'assets/content/about';
import { Grid, Box, Typography, Link } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import imageSK from 'assets/images/sk.png';
import imageMA from 'assets/images/ma.png';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  profileImg: {
    width: '100%',
    // maxWidth: 350,
    borderRadius: 12,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
    },
  },
  emailLink: {
    marginBottom: theme.spacing(2),
  },
}));

const AboutPage: React.FC = (): JSX.Element => {
  const styles = useStyles({});

  return (
    <PageContainer>
      <Helmet>
        <title>distriqt - About us</title>
        <link rel="canonical" href="https://distriqt.com/about" />
      </Helmet>
      <FullWidth backgroundImage={bgImage as string} useClipPath>
        <Section id="contact">
          <HeroSection content={heroContent} />
        </Section>
      </FullWidth>
      <Section id="about-intro">
        <ContentSection content={introContent} showDivider={false} />
      </Section>
      <Section id="about-us">
        <Grid container spacing={10}>
          <Grid item xs={12} sm={12} md={6}>
            <Box position="relative">
              <img className={styles.profileImg} src={imageMA} alt="shane korin" />
              <Box my={2}>
                <Typography variant="h3" component="h2">
                  Michael Archbold
                </Typography>
                <div className={styles.emailLink}>
                  <Link href="mailto:ma@distriqt.com">ma@distriqt.com</Link>
                </div>
                <Typography variant="body1" component="p">
                  Michael is a software developer with a passion for maths, physics and
                  technology. He has over 25 years of extensive experience in software
                  development, web and mobile applications, GPS solutions and embedded
                  systems.
                </Typography>
                <Typography variant="body1" component="p">
                  With a wealth of knowledge in many areas of IT, Michael specialises
                  heavily in mobile and desktop platform solutions for many different
                  operating systems and platforms.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box position="relative">
              <img className={styles.profileImg} src={imageSK} alt="shane korin" />
              <Box my={2}>
                <Typography variant="h3" component="h2">
                  Shane Korin
                </Typography>
                <div className={styles.emailLink}>
                  <Link className={styles.emailLink} href="mailto:sk@distriqt.com">
                    sk@distriqt.com
                  </Link>
                </div>
                <Typography variant="body1" component="p">
                  Shane has been working in multiple software and games industries for
                  over 20 years. With extensive experience in web development, mobile and
                  software applications and e-learning, to game programming and UI
                  development.
                </Typography>
                <Typography variant="body1" component="p">
                  Currently, Shane specialises mostly in front-end engineering for the
                  modern web, as well as mobile and full-stack development. His main focus
                  is React and Node, plus leading front-end teams and designing solutions
                  for the modern web.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Section>
    </PageContainer>
  );
};

export default AboutPage;
