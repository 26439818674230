import { HeroSection, ContentSectionData } from 'types/domain/content';

// Main homepage hero content
export const heroContent: HeroSection = {
  title:
    'distriqt is a <b>digital consultancy</b>, providing development and technology services for modern businesses.',
};

// Main intro text section
export const introContent: ContentSectionData = {
  title: 'Contact us',
  items: [
    {
      title: '',
      paragraphs: [
        "To enquire about our services, or ask a question - just fill out the form below and we'll get back to you.",
      ],
    },
  ],
};
