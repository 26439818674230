import React, { useState } from 'react';
import { Grid, Box, Typography, Link, Hidden, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import LogoImage from 'assets/images/distriqt-logo.png';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    zIndex: 100,
    [theme.breakpoints.up('md')]: {
      right: 0,
      top: 108,
    },
    [theme.breakpoints.down('sm')]: {
      top: 0,
      right: 0,
    },
  },
  box: {
    color: theme.palette.common.white,
  },
  content: {
    textAlign: 'right',
  },
  link: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '1.8rem',
    marginLeft: theme.spacing(2),
  },
  drawer: {
    backgroundColor: theme.palette.primary.dark,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  menuButton: {
    position: 'static',
    right: 0,
    top: 0,
    transform: 'scale(1.5)',
  },
  menuItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  menuLink: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '1.8rem',
    marginLeft: theme.spacing(2),
  },
  logoImage: {
    height: 50,
    width: 'auto',
  },
}));

const Header: React.FC = (): JSX.Element => {
  const styles = useStyles({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <header className={styles.root}>
      <Hidden smDown>
        <Grid container>
          <Grid item xs={12} className={styles.content}>
            <Box className={styles.box}>
              <Link href="/#services" className={styles.link}>
                Services
              </Link>
              <Link href="/#clients" className={styles.link}>
                Clients
              </Link>
              <Link href="/about" className={styles.link}>
                About us
              </Link>
              <Link href="/contact" className={styles.link}>
                Contact us
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <div className={styles.menuButton}>
          <IconButton size="medium">
            <MenuIcon
              fontSize="large"
              htmlColor="#FFFFFF"
              onClick={() => setIsDrawerOpen(true)}
            />
          </IconButton>
        </div>
        <SwipeableDrawer
          anchor="top"
          open={isDrawerOpen}
          onOpen={() => setIsDrawerOpen(true)}
          onClose={() => setIsDrawerOpen(false)}
        >
          <Grid container className={styles.drawer}>
            <Grid item xs={12} className={styles.menuItem}>
              <Link href="/" className={styles.menuLink}>
                <img className={styles.logoImage} src={LogoImage} alt="home logo" />
              </Link>
            </Grid>
            <Grid item xs={12} className={styles.menuItem}>
              <Link href="/#services" className={styles.menuLink}>
                Services
              </Link>
            </Grid>
            <Grid item xs={12} className={styles.menuItem}>
              <Link href="/#clients" className={styles.menuLink}>
                Clients
              </Link>
            </Grid>
            <Grid item xs={12} className={styles.menuItem}>
              <Link href="/about" className={styles.menuLink}>
                About us
              </Link>
            </Grid>
            <Grid item xs={12} className={styles.menuItem}>
              <Link href="/contact" className={styles.menuLink}>
                Contact us
              </Link>
            </Grid>
            <Grid item xs={12} className={styles.menuItem}>
              <Link onClick={() => setIsDrawerOpen(false)} className={styles.menuLink}>
                Close
              </Link>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Hidden>
    </header>
  );
};

export default Header;
