import React, { useState, useReducer, useEffect } from 'react';
import { Grid, Box, TextField, Button, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import axios from 'axios';

const useStyles = makeStyles((theme: Theme) => ({
  box: {},
  root: {},
  buttonRow: {
    textAlign: 'right',
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  errorRow: {
    color: theme.palette.error.main,
  },
}));

interface FieldState {
  value: string;
  touched: boolean;
  error: string;
}

interface FormState {
  name: FieldState;
  email: FieldState;
  message: FieldState;
}

enum FieldName {
  NAME = 'name',
  EMAIL = 'email',
  MESSAGE = 'message',
}

enum FieldAction {
  CHANGE = 'CHANGE',
  BLUR = 'BLUR',
  VALIDATE = 'VALIDATE',
  CLEAR = 'CLEAR',
}

type Action = { type: FieldAction; field?: FieldName; payload?: Partial<FieldState> };

const formReducer = (state: FormState, action: Action): FormState => {
  const { field, payload } = action;

  if (field && payload) {
    switch (action.type) {
      case FieldAction.CHANGE:
        return {
          ...state,
          [field]: {
            ...state[field],
            value: payload.value,
          },
        };
      case FieldAction.BLUR:
        return {
          ...state,
          [field]: {
            ...state[field],
            touched: payload.touched,
          },
        };
    }
  } else {
    if (action.type === FieldAction.VALIDATE) {
      const newState = { ...state };
      if (!state.name.value) {
        newState.name.error = 'Name is required';
      } else {
        newState.name.error = '';
      }

      if (!state.email.value) {
        newState.email.error = 'Email is required';
      } else {
        newState.email.error = '';
      }

      if (!state.message.value) {
        newState.message.error = 'Please enter a message';
      } else {
        newState.message.error = '';
      }

      return newState;
    }

    if (action.type === FieldAction.CLEAR) {
      const newState = { ...state };
      newState.name = {
        value: '',
        touched: false,
        error: '',
      };

      newState.email = {
        value: '',
        touched: false,
        error: '',
      };

      newState.message = {
        value: '',
        touched: false,
        error: '',
      };

      return newState;
    }
  }

  return state;
};

/**
 * ContactForm component
 */
const ContactForm: React.FC = (): JSX.Element => {
  const styles = useStyles({});
  const [isSubmitTriggered, setIsSubmitTriggered] = useState(false);
  const [isSubmitPending, setIsSubmitPending] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitComplete, setIsSubmitComplete] = useState(false);
  const [isSubmitError, setSubmitError] = useState(false);
  const [state, dispatch] = useReducer(formReducer, {
    name: {
      value: '',
      touched: false,
      error: '',
    },
    email: {
      value: '',
      touched: false,
      error: '',
    },
    message: {
      value: '',
      touched: false,
      error: '',
    },
  });

  const onChange = (evt): void => {
    dispatch({
      type: FieldAction.CHANGE,
      field: evt.target.id as FieldName,
      payload: {
        value: evt.target.value,
      },
    });
  };

  const onBlur = (evt): void => {
    dispatch({
      type: FieldAction.BLUR,
      field: evt.target.id as FieldName,
      payload: {
        touched: true,
      },
    });
  };

  const handleSubmit = (): void => {
    setIsSubmitPending(true);
    dispatch({ type: FieldAction.VALIDATE });

    setTimeout(() => {
      setIsSubmitTriggered(true);
    }, 200);
  };

  const sendFormData = async (): Promise<void> => {
    setSubmitError(false);
    const res = await axios.post('/mail.php', {
      name: state.name.value,
      email: state.email.value,
      message: state.message.value,
    });
    setIsSubmitPending(false);

    if (res.data === 'ok') {
      setIsSubmitComplete(true);
      dispatch({
        type: FieldAction.CLEAR,
      });
    } else {
      setSubmitError(true);
      setIsSubmitTriggered(false);
      setIsSubmitComplete(false);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (isSubmitPending && isSubmitTriggered && !isSubmitting) {
      let hasError = false;
      setIsSubmitTriggered(false);
      for (const field in state) {
        if (state[field as FieldName].error !== '') {
          hasError = true;
        }
      }

      if (!hasError) {
        setIsSubmitting(true);
        // submit now
        sendFormData();
      } else {
        setIsSubmitTriggered(false);
        setIsSubmitPending(false);
      }
    }
  }, [isSubmitTriggered, isSubmitPending, isSubmitting]);

  const isSending = isSubmitPending || isSubmitTriggered || isSubmitting;

  return (
    <Box className={styles.box}>
      <Grid container className={styles.root} spacing={4}>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            id="name"
            label="Your name"
            placeholder="Your name"
            variant="outlined"
            value={state.name.value}
            error={state.name.error !== ''}
            helperText={state.name.error || ''}
            fullWidth
            onChange={onChange}
            onBlur={onBlur}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            id="email"
            label="Your email"
            placeholder="Your email"
            variant="outlined"
            type="email"
            value={state.email.value}
            error={state.email.error !== ''}
            helperText={state.email.error || ''}
            fullWidth
            onChange={onChange}
            onBlur={onBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="message"
            label="Message"
            placeholder="Message"
            variant="outlined"
            value={state.message.value}
            error={state.message.error !== ''}
            helperText={state.message.error || ''}
            fullWidth
            multiline
            rows={4}
            onChange={onChange}
            onBlur={onBlur}
          />
        </Grid>
        {isSubmitError && (
          <Grid item xs={12} className={styles.errorRow}>
            <Typography>Sorry, something went wrong. Please try again.</Typography>
          </Grid>
        )}
        <Grid item xs={12} className={styles.buttonRow}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={styles.button}
            onClick={handleSubmit}
            disabled={isSending}
          >
            Send message
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactForm;
