import React from 'react';
import './_includes';
import Helmet from 'react-helmet';
import PageContainer from 'containers/PageContainer';
import FullWidth from 'containers/FullWidthContainer';
import Section from 'containers/SectionContainer';
import HeroSection from 'components/HeroSection';
import ContentSection from 'components/ContentSection';
import bgImage from 'assets/images/promo-background-darker.jpg';
import { heroContent } from 'assets/content/home';

const NativeExtensionsPage: React.FC = (): JSX.Element => {
  return (
    <PageContainer>
      <Helmet>
        <title>distriqt - Native Extensions</title>
        <link rel="canonical" href="https://distriqt.com/404" />
      </Helmet>
      <FullWidth backgroundImage={bgImage as string} useClipPath>
        <Section id="404">
          <HeroSection content={heroContent} />
        </Section> */}
      </FullWidth>
      <Section id="contact-intro">
        <ContentSection
          content={{
            title: 'Page not found!',
            items: [
              {
                paragraphs: ['The requested page does not exist.'],
              },
            ],
          }}
          showDivider={false}
        />
      </Section>
    </PageContainer>
  );
};

export default NativeExtensionsPage;
