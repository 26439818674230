import React from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DividerLine from 'components/DividerLine';
import ImageGrid from 'components/ImageGrid';
import { ContentSectionData } from 'types/domain/content';
import parse from 'react-html-parser';

export interface ContentSectionProps {
  content: ContentSectionData;
  showDivider?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  buttonRow: {
    textAlign: 'right',
    '& > a': {
      marginLeft: theme.spacing(2),
    },
  },
  item: {
    marginBottom: theme.spacing(4),
  },
  ctaButton: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

const ContentSection: React.FC<ContentSectionProps> = ({
  content,
  showDivider = true,
}: ContentSectionProps): JSX.Element => {
  const styles = useStyles({});

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box py={8}>
          {showDivider && <DividerLine />}
          {content.title && (
            <Typography variant="h2" component="h2">
              {parse(content.title)}
            </Typography>
          )}
          {content.description && (
            <Typography variant="body1" component="p">
              {parse(content.description)}
            </Typography>
          )}
          {content.items?.map((item, idx) => {
            console.log(item.title, item.ctas);

            return (
              <div key={`item_${idx}`} className={styles.item}>
                {item.title && (
                  <Typography variant="h3" component="h3">
                    {parse(item.title)}
                  </Typography>
                )}
                {item.paragraphs &&
                  item.paragraphs.map((para, pidx) => {
                    return (
                      <Typography variant="body1" component="p" key={`para_${pidx}`}>
                        {parse(para)}
                      </Typography>
                    );
                  })}
                {item.ctas &&
                  item.ctas?.map((cta, index) => {
                    return (
                      <Button
                        key={`cta_${index}`}
                        className={styles.ctaButton}
                        variant="contained"
                        color="primary"
                        href={cta.href}
                        target={cta.target || '_self'}
                      >
                        {cta.label}
                      </Button>
                    );
                  })}
              </div>
            );
          })}
          {content.images && content.images.length > 0 && (
            <ImageGrid images={content.images} />
          )}
          {content.ctas && (
            <Box py={4} className={styles.buttonRow}>
              {content.ctas?.map((cta, index) => {
                return (
                  <Button
                    key={`cta_${index}`}
                    variant="contained"
                    color="primary"
                    href={cta.href}
                    target={cta.target || '_self'}
                  >
                    {cta.label}
                  </Button>
                );
              })}
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ContentSection;
