import React, { useEffect } from 'react';
import './_includes';
import PageContainer from '../containers/PageContainer';
import FullWidth from 'containers/FullWidthContainer';
import Section from 'containers/SectionContainer';
import HeroSection from 'components/HeroSection';
import ContentSection from 'components/ContentSection';
import ContactForm from 'components/ContactForm';
import { Helmet } from 'react-helmet';
import bgImage from 'assets/images/promo-background-darker.jpg';
import { heroContent, introContent } from 'assets/content/contact';

const ContactPage: React.FC = (): JSX.Element => {
  useEffect(() => {
    const el = document.querySelector('#contact-intro');
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <PageContainer>
      <Helmet>
        <title>distriqt - Contact us</title>
        <link rel="canonical" href="https://distriqt.com/contact" />
      </Helmet>
      <FullWidth backgroundImage={bgImage as string} useClipPath>
        <Section id="contact">
          <HeroSection content={heroContent} />
        </Section>
      </FullWidth>
      <Section id="contact-intro">
        <ContentSection content={introContent} showDivider={false} />
      </Section>
      <Section id="contact-form">
        <ContactForm />
      </Section>
    </PageContainer>
  );
};

export default ContactPage;
