import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Box, Typography, Link } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import LogoImage from 'assets/images/distriqt-logo.png';
import { HeroSection as HeroSectionContent } from 'types/domain/content';
import parse from 'react-html-parser';

export interface HeroSectionProps {
  content: HeroSectionContent;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  box: {
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(14),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(8),
    }
  },
  logoWrapper: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  heroText: {
    fontWeight: 500,
    color: theme.palette.common.white,
    maxWidth: '90rem',
    paddingTop: '6rem',
    paddingBottom: '6rem',
    textShadow: '2px 2px rgba(0, 0, 0, 0.3)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
      textAlign: 'center',
    },
  },
}));

const HeroSection: React.FC<HeroSectionProps> = ({
  content,
}: HeroSectionProps): JSX.Element => {
  const styles = useStyles({});
  const [height, setHeight] = useState(0);
  const [isScrollHeaderVis, setIsScrollHeaderVis] = useState(false);

console.log(height);

  function onScroll() {
    // console.log('h in: ', height);
    /*
    console.log('scoll : ', `h ${height}  /  s: ${window.scrollY}` );
    
    if (height > 0) {
      if (window.scrollY >= height) {
        if (!isScrollHeaderVis) {
          console.log('show');
          setIsScrollHeaderVis(true);
        }
      } else {
        if (isScrollHeaderVis) {
          console.log('hide');
          setIsScrollHeaderVis(false);
        }
      }
    }
    */
  }

  useEffect(() => {
    setTimeout(() => {
      const el = document.querySelector('#hero-container');

      if (el) {
        // console.log(el.getBoundingClientRect().height);
        setHeight(el.getBoundingClientRect().height);
        window.addEventListener('scroll', onScroll);
      }
    }, 1000);

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, []);  

  return (
    <Grid container id="hero-container">
      <Grid item xs={12}>
        <Box className={styles.box}>
          <div className={styles.logoWrapper}>
            <Link href="/">
              <img src={LogoImage} alt="distriqt logo" />
            </Link>
          </div>
          <Typography variant="h2" component="h1" className={styles.heroText}>
            {parse(content.title)}
          </Typography>
        </Box>
      </Grid>
      {
        isScrollHeaderVis && (
          <Grid item xs={12}><h1>HERE I AM</h1></Grid>
        )
      }
    </Grid>
  );
};

export default HeroSection;
